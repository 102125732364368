
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  props: {
    images: {type: Array, required: true},
    index: {type: [Number, null]},
  },
  components: {
    'vue-easy-lightbox': VueEasyLightbox,
  },
  methods: {
    close(): void {
      this.$emit('close');
    },
  },
  computed: {
    visible(): boolean {
      return this.$props.index !== null;
    },
    lightboxImages(): object[] {
      return this.$props.images.map(image => ({src: image.url}));
    },
  },
};
